import Card from "../components/common/card/card";
import { DIABLO4_ROUTE, DEAD_BY_DAYLIGHT_ROUTE } from "../utils/consts";

export default function Home() {
  return (
    <div className="flex justify-center p-4">
      <main className="flex flex-col items-center gap-5 border border-zinc-500 bg-zinc-800 py-4 drop-shadow-2xl md:w-3/4">
        <h1 className="px-12 text-3xl">
          Welcome to <span className="text-red-500">Gaming Toolkits!</span>
        </h1>

        <h2 className="px-12">
          Empower Your Gaming with Gaming Toolkits comprehensive list of tools!
        </h2>
        <p className="px-12">
          Unleash your inner strategist with our mapping tools, craft
          unstoppable builds with character optimization tools, automate
          repetitive tasks with automation tools, uncover hidden insights with
          data analysis tools, and enjoy seamless accessibility on the go.
        </p>

        <h2 className="px-12">
          Unleash the Limitless Potential of Your Gaming
        </h2>

        <p className="px-12">
          Visit our various tools for the following games and elevate your
          gaming experience to new heights.
        </p>

        <div className="flex w-3/4 flex-wrap">
          <Card
            link={DIABLO4_ROUTE}
            image="/images/home/d4-icon-big.webp"
            imageAlt="Diablo 4 icon"
            title="Diablo 4 Toolkit"
            description="Various of tool for Diablo 4!"
          />

          <Card
            link={DEAD_BY_DAYLIGHT_ROUTE}
            image="/images/home/dbd-icon-big.webp"
            imageAlt="Diablo 4 icon"
            title="Dead By Daylight Toolkit"
            description="Various of tool for Dead By Daylight!"
          />
        </div>
      </main>
    </div>
  );
}
